import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import Old2005BlogPage from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—September 2009" />
	<Old2005BlogPage>
		<h2>September 2009</h2>



		<h3 className="blogdate">
		Tuesday, September 01, 2009
		</h3>






		<h4 className="blogitemtitle">
		Talking is tiring, singing is not
		</h4>





		   <div className="blogitembody">
		   Talking fatigues me - it often feels like it's an effort to be audible, clear and articulate.  I actually do feel fatigued after talking for an hour or more - I notice a strain and exhaustion in my throat and neck, and I feel like my throat needs a lot of.. ahem... clearing.  Take tonight for instance, I was in public for a few hours and did a fair amount of talking - throughout which I noticed I was getting drained.<br /><br />Afterwards, on my cycle home through the night, I cleared my throat and starting singing.  I let the front and top of my mouth amplify the higher notes, I supported from my stomach area and I felt my whole upper body behind my sound.  It was effortless and clear -  I felt like an opera singer, yet opera singer I am not.  I relaxed the back of my throat and just let it be a passage to the mouth chamber.  Would you believe that this recharged me?<br /><br />A few minutes of singing made me feel much better.  I remember days when singing was highly draining and taxing for me... so maybe the years of practicing singing have paid off?  Perhaps this is what good vocal technique should feel like - effortless, clear, fluent, strong, satisfying, efficient, smooth.  It just plain feels good!<br /><br />Maybe I should sing more when I talk?  Or find some other way of applying some of these techniques or "feelings" to my discussions?<br /><br />Anyhow, speaking of singing, <a href="http://www.youtube.com/watch?v=7_V1SLSauak">here's a nice clip</a> of Martin Sexton showing us how it's done.
		   </div>
		   <div className="blogitemfooter">
		   posted by Jesse @ 9:21 PM &nbsp;
		   </div>





		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
